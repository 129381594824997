import React, { useState, useEffect } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import DailyVisitsChart from './DailyVisitsChart';

function Admin() {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [bonusData, setBonusData] = useState({
    casino_name: '',
    bonus_description: '',
    bonus_link: '',
    site_description: '',
  });
  const [blogData, setBlogData] = useState({
    author: '',
    title: '',
    content: '',
    link: '',
  });

  const [bonuses, setBonuses] = useState([]);
  const [posts, setPosts] = useState([]);
  const [contactMessages, setContactMessages] = useState([]);

  const [editBonus, setEditBonus] = useState(null);
  const [editPost, setEditPost] = useState(null);

  const AUTH_KEY = '1634MillerAveMurrayKY42071';

  useEffect(() => {
    if (authenticated) {
      fetchBonuses();
      fetchBlogPosts();
      fetchContactMessages();
    }
  }, [authenticated]);

  const authenticate = () => {
    if (password === AUTH_KEY) {
      setAuthenticated(true);
    } else {
      alert('Password incorrect!');
    }
  };

  const fetchBonuses = async () => {
    try {
      const response = await axios.get('https://api.blackboxbonuses.com/api/bonuses?page=1&limit=100');
      setBonuses(response.data.data);
    } catch (error) {
      console.error('Error fetching bonuses:', error);
    }
  };

  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get('https://api.blackboxbonuses.com/api/blog');
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
  };

  const fetchContactMessages = async () => {
    try {
      const response = await axios.get('https://api.blackboxbonuses.com/api/contact-messages', {
        headers: {
          Authorization: AUTH_KEY,
        },
      });
      setContactMessages(response.data);
    } catch (error) {
      console.error('Error fetching contact messages:', error);
    }
  };

  const handleDeleteBonus = async (id) => {
    if (!window.confirm('Are you sure you want to delete this bonus?')) return;

    try {
      await axios.delete(`https://api.blackboxbonuses.com/api/bonuses/${id}`, {
        data: { auth_key: AUTH_KEY }
      });
      setBonuses(bonuses.filter(bonus => bonus.id !== id));
      alert('Bonus deleted successfully!');
    } catch (error) {
      console.error('Error deleting bonus:', error);
    }
  };

  const handleDeletePost = async (id) => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;

    try {
      await axios.delete(`https://api.blackboxbonuses.com/api/blog/${id}`, {
        data: { auth_key: AUTH_KEY }
      });
      setPosts(posts.filter(post => post.id !== id));
      alert('Blog post deleted successfully!');
    } catch (error) {
      console.error('Error deleting blog post:', error);
    }
  };

  const handleDeleteMessage = async (id) => {
    if (!window.confirm('Are you sure you want to delete this message?')) return;

    try {
      await axios.delete(`https://api.blackboxbonuses.com/api/contact-messages/${id}`, {
        headers: {
          Authorization: AUTH_KEY,
        },
      });
      setContactMessages(contactMessages.filter(message => message.id !== id));
      alert('Message deleted successfully!');
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleBonusSubmit = async (e) => {
    e.preventDefault();

    const dataWithAuth = {
      ...bonusData,
      auth_key: AUTH_KEY,
    };

    try {
      const response = await axios.post('https://api.blackboxbonuses.com/api/bonuses', dataWithAuth);
      alert('Bonus added successfully!');
      setBonusData({ casino_name: '', bonus_description: '', bonus_link: '', site_description: '' });
      fetchBonuses();
    } catch (error) {
      console.error('Error submitting bonus:', error);
    }
  };

  const handleBlogSubmit = async (e) => {
    e.preventDefault();

    const dataWithAuth = {
      ...blogData,
      auth_key: AUTH_KEY,
    };

    try {
      const response = await axios.post('https://api.blackboxbonuses.com/api/blog', dataWithAuth);
      alert('Blog post added successfully!');
      setBlogData({ author: '', title: '', content: '', link: '' });
      fetchBlogPosts();
    } catch (error) {
      console.error('Error submitting blog post:', error);
    }
  };

  const handleEditSubmitBonus = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`https://api.blackboxbonuses.com/api/bonuses/${editBonus.id}`, {
        ...editBonus,
        auth_key: AUTH_KEY
      });
      setBonuses(bonuses.map(bonus => (bonus.id === editBonus.id ? editBonus : bonus)));
      setEditBonus(null);
      alert('Bonus modified successfully!');
    } catch (error) {
      console.error('Error modifying bonus:', error);
    }
  };

  const handleEditSubmitPost = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`https://api.blackboxbonuses.com/api/blog/${editPost.id}`, {
        ...editPost,
        auth_key: AUTH_KEY
      });
      setPosts(posts.map(post => (post.id === editPost.id ? editPost : post)));
      setEditPost(null);
      alert('Blog post modified successfully!');
    } catch (error) {
      console.error('Error modifying blog post:', error);
    }
  };

  return (
    <div className="min-h-screen p-5 bg-black flex items-center justify-center">
      {!authenticated ? (
        <div className="max-w-sm bg-black p-8 rounded-lg shadow-md">
          <div className="flex items-center justify-center mb-6">
            <LockClosedIcon className="h-12 w-12 text-blue-500" />
          </div>
          <h2 className="text-xl font-bold mb-4">Admin Login</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
            className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
          />
          <button
            onClick={authenticate}
            className="w-full py-2 bg-indigo-600 text-black rounded-md hover:bg-indigo-700 shadow-md"
          >
            Submit
          </button>
        </div>
      ) : (
        <div>
          <DailyVisitsChart />

          <div className="max-w-lg mb-8">
            <h2 className="text-2xl font-bold mb-5">Add New Bonus</h2>
            <form onSubmit={handleBonusSubmit} className="bg-black p-4 rounded shadow-sm mb-6">
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Site Name</label>
                <input
                  type="text"
                  name="casino_name"
                  value={bonusData.casino_name}
                  onChange={(e) => setBonusData({ ...bonusData, casino_name: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Bonus Description</label>
                <input
                  type="text"
                  name="bonus_description"
                  value={bonusData.bonus_description}
                  onChange={(e) => setBonusData({ ...bonusData, bonus_description: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Bonus Link</label>
                <input
                  type="url"
                  name="bonus_link"
                  value={bonusData.bonus_link}
                  onChange={(e) => setBonusData({ ...bonusData, bonus_link: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Site Description</label>
                <textarea
                  name="site_description"
                  value={bonusData.site_description}
                  onChange={(e) => setBonusData({ ...bonusData, site_description: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 bg-green-600 text-black rounded hover:bg-green-700"
              >
                Submit Bonus
              </button>
            </form>
          </div>

          <div className="max-w-lg mb-8">
            <h2 className="text-2xl font-bold mb-5">Add New Blog Post</h2>
            <form onSubmit={handleBlogSubmit} className="bg-white p-4 rounded shadow-sm mb-6">
              <div className="mb-4">
                <label className="block text-black text-sm font-medium mb-1">Author</label>
                <input
                  type="text"
                  name="author"
                  value={blogData.author}
                  onChange={(e) => setBlogData({ ...blogData, author: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-black text-sm font-medium mb-1">Title</label>
                <input
                  type="text"
                  name="title"
                  value={blogData.title}
                  onChange={(e) => setBlogData({ ...blogData, title: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-black text-sm font-medium mb-1">Content</label>
                <textarea
                  name="content"
                  value={blogData.content}
                  onChange={(e) => setBlogData({ ...blogData, content: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-black text-sm font-medium mb-1">Link</label>
                <input
                  type="url"
                  name="link"
                  value={blogData.link}
                  onChange={(e) => setBlogData({ ...blogData, link: e.target.value })}
                  className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 bg-blue-600 text-black rounded hover:bg-blue-700"
              >
                Submit Blog
              </button>
            </form>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-5">Modify or Delete Bonuses</h2>
            {bonuses.map(bonus => (
              <div key={bonus.id} className="bg-white p-4 mb-4 rounded shadow-sm flex justify-between items-center">
                <div>
                  <h3 className="text-lg text-black font-semibold">{bonus.casino_name}</h3>
                  <p className="text-sm text-black">{bonus.bonus_description}</p>
                  <p className="text-sm text-gray-500">{bonus.site_description}</p>
                </div>
                <div>
                  <button
                    className="text-blue-500 hover:underline mr-2"
                    onClick={() => setEditBonus(bonus)}
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 hover:underline"
                    onClick={() => handleDeleteBonus(bonus.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            {editBonus && (
              <form onSubmit={handleEditSubmitBonus} className="bg-white p-4 rounded shadow-sm mt-4">
                <h3 className="text-lg font-bold mb-4">Edit Bonus</h3>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Site Name</label>
                  <input
                    type="text"
                    name="casino_name"
                    value={editBonus.casino_name}
                    onChange={(e) => setEditBonus({ ...editBonus, casino_name: e.target.value })}
                    className="w-full p-2 border rounded"
                    style={{ color: 'black' }}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Bonus Description</label>
                  <input
                    type="text"
                    name="bonus_description"
                    value={editBonus.bonus_description}
                    onChange={(e) => setEditBonus({ ...editBonus, bonus_description: e.target.value })}
                    className="w-full p-2 border rounded text-black"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Bonus Link</label>
                  <input
                    type="url"
                    name="bonus_link"
                    value={editBonus.bonus_link}
                    onChange={(e) => setEditBonus({ ...editBonus, bonus_link: e.target.value })}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Site Description</label>
                  <textarea
                    name="site_description"
                    value={editBonus.site_description}
                    onChange={(e) => setEditBonus({ ...editBonus, site_description: e.target.value })}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 bg-green-600 text-black rounded hover:bg-green-700"
                >
                  Save Changes
                </button>
              </form>
            )}
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-5">Modify or Delete Blog Posts</h2>
            {posts.map(post => (
              <div key={post.id} className="bg-white p-4 mb-4 rounded shadow-sm flex justify-between items-center">
                <div>
                  <h3 className="text-lg text-black font-semibold">{post.title}</h3>
                  <p className="text-sm text-black">{post.content}</p>
                  <p className="text-sm text-gray-500">By {post.author}</p>
                </div>
                <div>
                  <button
                    className="text-blue-500 hover:underline mr-2"
                    onClick={() => setEditPost(post)}
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 hover:underline"
                    onClick={() => handleDeletePost(post.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            {editPost && (
              <form onSubmit={handleEditSubmitPost} className="bg-white p-4 rounded shadow-sm mt-4">
                <h3 className="text-lg font-bold mb-4">Edit Blog Post</h3>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={editPost.title}
                    onChange={(e) => setEditPost({ ...editPost, title: e.target.value })}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Author</label>
                  <input
                    type="text"
                    name="author"
                    value={editPost.author}
                    onChange={(e) => setEditPost({ ...editPost, author: e.target.value })}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Content</label>
                  <textarea
                    name="content"
                    value={editPost.content}
                    onChange={(e) => setEditPost({ ...editPost, content: e.target.value })}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Link</label>
                  <input
                    type="url"
                    name="link"
                    value={editPost.link}
                    onChange={(e) => setEditPost({ ...editPost, link: e.target.value })}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 bg-green-600 text-black rounded hover:bg-green-700"
                >
                  Save Changes
                </button>
              </form>
            )}
          </div>

          {/* Display contact messages*/}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-5">Contact Messages</h2>
            {contactMessages.map(message => (
              <div key={message.id} className="bg-white p-4 mb-4 rounded shadow-sm flex justify-between items-center">
                <div>
                  <h3 className="text-lg text-black font-semibold">{message.name}</h3>
                  <p className="text-sm text-black">{message.email}</p>
                  <p className="text-sm text-gray-500">{message.message}</p>
                </div>
                <div>
                  <button
                    className="text-red-500 hover:underline"
                    onClick={() => handleDeleteMessage(message.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Admin;