// Import necessary libraries and hooks
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// Create and export the AboutUs component
export default function AboutUs() {
  const [darkMode, setDarkMode] = useState(true);

  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} min-h-screen transition-colors duration-300 flex flex-col`}>
      {/* Navigation bar */}
      <Helmet>
        <title>Black Box Bonuses - About Us</title>
        <meta name="description" content="BlackBoxBonuses provides the best and newest sign up offers for casinos, credit cards, banks, and more. " />
        <meta name="keywords" content="blackboxbonuses, blackboxbonuses about us, is blackboxbonuses legit" />
      </Helmet>
      <nav className="flex justify-between items-center p-5 shadow-md">
        <a href='/' className="text-2xl font-semibold font-sans">Black Box Bonuses</a>
        <div className="flex space-x-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            onClick={toggleDarkMode}
          >
            {darkMode ? 'Light Mode' : 'Dark Mode'}
          </button>
        </div>
      </nav>

      {/* Main content */}
      <main className="flex-grow p-8 space-y-12">
      <div className="flex justify-center items-center mt-8">
        <img src="/logo-removebg.png" alt="Logo" className="h-64 w-64" />
      </div>
        <section className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold font-sans mb-4 text-center">
            About Us
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Welcome to BlackBoxBonuses, your ultimate destination for discovering all online offers across various domains, including online gambling, slots, sports betting, investing, and more. Our goal is to provide a comprehensive list of all the bonuses available, ensuring that no opportunity goes unnoticed.
          </p>
        </section>

        <section className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            Our Mission
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            At BlackBoxBonuses, we believe in the power of informed choices. That’s why we are driven by a mission to empower our users with the most thorough and up-to-date information regarding all online offers. We understand the complexities of online promotions and are dedicated to demystifying them for you.
          </p>
        </section>

        <section className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            Comprehensive Coverage
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Our platform meticulously documents every available offer, from gambling sites to investment platforms. We go beyond simple listings to bring you insights and evaluations of each site’s offerings. Whether you're searching for the best online casino bonuses, sports betting promotions, or investment deals, we've got you covered.
          </p>
        </section>

        <section className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            Rigorous Testing
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            To ensure the reliability of our content, every site listed on BlackBoxBonuses is put through rigorous testing by our experienced team. We test every feature, evaluate user experiences, and verify promotional terms to guarantee that our recommendations stand up to scrutiny.
          </p>
        </section>

        <section className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            Trusted by Users
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            We pride ourselves on being a trusted resource for users worldwide. Our dedication to honesty, transparency, and quality has earned us a loyal following. Whether you're a seasoned online enthusiast or a newbie, our site is designed to cater to all levels of experience.
          </p>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-100 dark:bg-gray-800 p-5 text-center">
        <p className="text-gray-700 dark:text-gray-400 text-sm">
          At BlackBoxBonuses, we are committed to bringing you unparalleled access to every online offer, helping you navigate the world of online promotions with confidence and ease.
        </p>
      </footer>
    </div>
  );
}