import React, { useEffect, useState } from 'react';

export default function TotalBonusesComponent() {
  const [totalBonuses, setTotalBonuses] = useState(0);

  useEffect(() => {
    // Fetch total bonus count from the server
    const fetchBonuses = async () => {
      try {
        const response = await fetch('https://api.blackboxbonuses.com/api/bonuses');
        const data = await response.json();
        setTotalBonuses(data.total);
      } catch (error) {
        console.error('Error fetching total bonuses:', error);
      }
    };

    fetchBonuses();
  }, []);

  return (
    <div
      className="fixed bottom-1/2 left-4 transform -translate-y-1/2 w-72 h-24 bg-gradient-to-r from-orange-300 to-orange-500 border-yellow-600 border-2 rounded-lg shadow-lg flex flex-col items-center justify-center text-center text-gray-900 font-semibold"
      style={{
        background: 'linear-gradient(to right, #ffd700, #ffeb3b)',
        border: 'solid 3px #b8860b',
        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
      }}
    >
      <h2 className="text-xl font-bold">Total Bonuses</h2>
      <p className="text-lg">{totalBonuses} Bonuses</p>
      <p className="text-sm">Claim over $3000 in bonuses!</p>
    </div>
  );
}