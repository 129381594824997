// Import necessary libraries and hooks
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import TotalBonusesComponent from './TotalBonusesComponent';

// Create and export the Homepage component
export default function Homepage() {
  const [darkMode, setDarkMode] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const cookies = new Cookies();

  // Set initial state for dark mode using the cookie
  useEffect(() => {
    const darkModeCookie = cookies.get('darkMode');
    // Render dark mode if cookie is true or does not exist
    if (darkModeCookie === undefined || darkModeCookie === true) {
      setDarkMode(true);
      if (darkModeCookie === undefined) {
        cookies.set('darkMode', 'true', { path: '/' }); // Initialize cookie if absent
      }
    } else {
      setDarkMode(false);
    }
  }, []);

  // Toggle dark mode and save to cookie
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    cookies.set('darkMode', newDarkMode.toString(), { path: '/' });
  };

  // Handle search input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (searchQuery.trim() === '') {
      return;
    }

    // Navigate to the search results page with query
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} min-h-screen transition-colors duration-300 flex flex-col justify-between`}>
      {/* Navigation bar */}
      <Helmet>
        <title>Black Box Bonuses - Home</title>
        <meta name="description" content="BlackBoxBonuses provides the best and newest sign up offers for casinos, credit cards, banks, and more. " />
        <meta name="keywords" content="blackboxbonuses, best site bonuses, site list bonuses, free site offers, free slot spins, free casino bonus, credit card sign up bonus" />
      </Helmet>
      <nav className="flex justify-between items-center p-5 shadow-md">
        <div className="text-2xl font-semibold font-sans">Black Box Bonuses</div>
        <div className="flex space-x-4">
          <Link to="/sitelist" className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Site List</Link>
          <Link to="/about-us" className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">About Us</Link>
          <Link to="/blog" className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Blog</Link>
          <Link to="/contact" className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Contact Us</Link>
          <button className="px-4 py-2 border rounded-lg focus:outline-none" onClick={toggleDarkMode}>{darkMode ? 'Light Mode' : 'Dark Mode'}</button>
        </div>
      </nav>

      {/* Main content */}
      <main className="flex flex-col justify-center items-center flex-grow">
        <div className="flex justify-center items-center mt-8">
          <img src="/logo-removebg.png" alt="Logo" className="h-96 w-96" />
        </div>
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold font-sans mb-4">
            Search Online Bonuses
          </h1>
          <p className="mt-2 text-lg text-gray-600 dark:text-gray-300 max-w-lg mx-auto">
            Explore the top bonuses curated from reliable sources around the web. Whether you are looking for the newest discounts or exclusive deals, we have got you covered.
          </p>
        </div>
        <h3 className="text-sm text-gray-400 mb-4">Suggested Search Terms:</h3>
          {/* List of small bubbles showing example searches */}
          <div className="flex space-x-2 mb-4">
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">Welcome Bonus</p>
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">No Deposit</p>
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">Free Spins</p>
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">18+</p>

          </div>
        <form onSubmit={handleSearchSubmit} className="w-full max-w-md">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Search for bonuses..."
            className="w-full px-4 py-3 text-black border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button type="submit" className="mt-4 mb-5 w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Search
          </button>
        </form>
      </main>

      {/* What We Do Section */}
      <section className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} p-8 space-y-8`}>
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center mt-5 mb-4">
            What We Do
          </h2>
          <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-700'} text-center`}>
            At Black Box Bonuses, we scout for websites offering enticing sign-up bonuses and provide direct links to them. Our mission is to help you make the most out of your wagering experience by connecting you to trustworthy sources.
          </p>
          <ul className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4 text-center">
            <li className={`px-4 py-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`}>
              <h3 className="text-xl font-semibold mb-2">Investing Apps</h3>
              <p className={darkMode ? 'text-gray-400' : 'text-gray-600'}>Find the best sign-up bonuses and offers on top investment platforms.</p>
            </li>
            <li className={`px-4 py-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`}>
              <h3 className="text-xl font-semibold mb-2">Credit Cards</h3>
              <p className={darkMode ? 'text-gray-400' : 'text-gray-600'}>Discover lucrative credit card sign-up bonuses and rewards.</p>
            </li>
            <li className={`px-4 py-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`}>
              <h3 className="text-xl font-semibold mb-2">Slot Websites</h3>
              <p className={darkMode ? 'text-gray-400' : 'text-gray-600'}>Access exciting bonuses and promotions on the best slot sites.</p>
            </li>
            <li className={`px-4 py-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`}>
              <h3 className="text-xl font-semibold mb-2">Case Opening Websites</h3>
              <p className={darkMode ? 'text-gray-400' : 'text-gray-600'}>Benefit from exclusive cases and bonuses on popular case opening sites.</p>
            </li>
            <li className={`px-4 py-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`}>
              <h3 className="text-xl font-semibold mb-2">Sports Betting Apps</h3>
              <p className={darkMode ? 'text-gray-400' : 'text-gray-600'}>Get the best offers and promotions on popular sports betting platforms.</p>
            </li>
            <li className={`px-4 py-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`}>
              <h3 className="text-xl font-semibold mb-2">Savings Accounts</h3>
              <p className={darkMode ? 'text-gray-400' : 'text-gray-600'}>Get the best offers for opening new savings or checking accounts.</p>
            </li>
          </ul>
          <p className="mt-8 text-red-400 text-center">
            Disclaimer: Gambling involves risk. Please gamble responsibly. All offers featured are only for persons over the age of 18. Please ensure you comply with the legal requirements in your jurisdiction.
          </p>
        </div>
        <TotalBonusesComponent></TotalBonusesComponent>
      </section>

      {/* Footer */}
      <footer className="bg-gray-100 dark:bg-gray-800 p-5 text-center">
        <p className="text-gray-700 dark:text-gray-400 text-sm">
          At Black Box Bonuses, we commit to offering only connections to reputable, thoroughly vetted sites to ensure your experience is safe and rewarding.
        </p>
      </footer>
    </div>
  );
}