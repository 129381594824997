import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function SearchResults() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [cookies, setCookie] = useCookies(['darkMode', 'clickedLinks']);

  const darkModeCookie = cookies.darkMode !== undefined ? cookies.darkMode === 'true' : true;
  const [darkMode, setDarkMode] = useState(darkModeCookie);
  const location = useLocation();
  const navigate = useNavigate();


  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    if (cookies.darkMode) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
    if (query) {
      fetchResults(query);
    }
  }, [query]);

  const fetchResults = async (query) => {
    try {
      const response = await fetch(`https://api.blackboxbonuses.com/api/bonuses/search?q=${encodeURIComponent(query)}`);
      const data = await response.json();
      setResults(data.results || []);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    setCookie('darkMode', !darkMode, { path: '/' });
  };

  const handleLinkClick = (link) => {
    const updatedLinks = { ...cookies.clickedLinks, [link]: true };
    setCookie('clickedLinks', updatedLinks, { path: '/' });
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== '') {
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} min-h-screen transition-colors duration-300`}>
      <Helmet>
        <title>Black Box Bonuses - Search Our Bonuses</title>
        <meta name="description" content="BlackBoxBonuses provides the best and newest sign up offers for casinos, credit cards, banks, and more. " />
        <meta name="keywords" content="blackboxbonuses, best site bonuses, site list bonuses, free site offers, free slot spins, free casino bonus, credit card sign up bonus" />
      </Helmet>
      <nav className="flex justify-between items-center p-5 shadow-md">
        <div className="text-2xl font-semibold font-sans"><a href="/">Black Box Bonuses</a></div>
        <button
          className="px-4 py-2 border rounded-lg focus:outline-none"
          onClick={toggleDarkMode}
        >
          {darkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </nav>

      <div className="p-5">
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : results.length === 0 ? (
          <div className="text-center">No results found</div>
        ) : (
          <div className="overflow-x-auto">
            <table className={`min-w-full border-collapse ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg`}>
              <thead>
                <tr>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Status
                  </th>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Site Name
                  </th>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Site Description
                  </th>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Bonus Description
                  </th>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Link
                  </th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? (darkMode ? 'bg-gray-700' : 'bg-gray-100') : (darkMode ? 'bg-gray-600' : 'bg-white')} transition-colors duration-200`}>
                    <td className="p-3 text-center">
                      <span className={`inline-block w-5 h-5 rounded-full ${cookies.clickedLinks && cookies.clickedLinks[result.bonus_link] ? 'bg-green-500' : 'bg-red-500'}`}></span>
                    </td>
                    <td className="p-3">
                      <a 
                        href={result.bonus_link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-500 hover:underline" 
                        onClick={() => handleLinkClick(result.bonus_link)}
                      >
                        {result.casino_name}
                      </a>
                    </td>
                    <td className="p-3">{result.site_description}</td>
                    <td className="p-3">{result.bonus_description}</td>
                    <td className="p-3">
                      <a 
                        href={result.bonus_link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-500 hover:underline" 
                        onClick={() => handleLinkClick(result.bonus_link)}
                      >
                        Visit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="p-5">
        <form onSubmit={handleSearchSubmit} className={`w-full max-w-md mx-auto ${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>
          <h1 className="text-3xl font-semibold mb-4">Search Online Bonuses</h1>
          <h2 className="text-lg text-gray-600 dark:text-gray-300 max-w-lg mx-auto mb-4"> Explore the top bonuses curated from reliable sources around the web. Whether you are looking for the newest discounts or exclusive deals, we have got you covered.</h2>
          {/*Small gray italic text */}
          <h3 className="text-sm text-gray-400 mb-4">Suggested Search Terms:</h3>
          {/* List of small bubbles showing example searches */}
          <div className="flex space-x-2 mb-4">
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">Welcome Bonus</p>
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">No Deposit</p>
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">Free Spins</p>
            <p className="px-2 py-1 bg-gray-300 text-gray-800 rounded-full text-sm">18+</p>

          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Search for bonuses..."
            className="w-full px-4 py-3 text-black border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="mt-4 mb-5 w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Search
          </button>
        </form>
      </div>

      <div className="pt-10">
        <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'} rounded-lg shadow-md`}>
          <h2 className="text-3xl font-semibold mb-4">What We Do</h2>
          <p className="mb-4">
            At Black Box Bonuses, we specialize in finding the <strong>best sign up bonuses</strong> available.
            Whether it’s <strong>free slot spins</strong>, <strong>no deposit bonuses</strong>, or exclusive <strong>sign up bonuses</strong>, we’ve got you covered.
            Our team continuously scours the internet to bring you the latest and greatest in bonus offers.
          </p>
          <p className="mb-4">
            Looking for a <strong>free bonus</strong>? Or maybe you're interested in trying out exclusive <strong>free slot spins</strong> on
            the hottest games? Look no further! We make it our mission to provide you with up-to-date information on
            the most rewarding bonuses around.
          </p>
          <p>
            Our platform ensures that all the details regarding <strong>sign up bonuses</strong> are clear and
            straightforward, so you can enjoy a hassle-free gaming experience. Dive into our listings and discover
            your next big win today!
          </p>
        </div>
      </div>
    </div>
  );
}

export default SearchResults;