import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

// Register the necessary components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function DailyVisitsChart() { 
  const [chartData, setChartData] = useState({ labels: [], datasets: [] }); 
  const [isLoading, setIsLoading] = useState(true); 
 
  useEffect(() => { 
    fetchVisitData(); 
  }, []); 
 
  const fetchVisitData = async () => { 
    try { 
      const response = await axios.get('https://api.blackboxbonuses.com/api/daily-visits'); 
      setIsLoading(false); 
 
      const visitDates = response.data.map(visit => visit.visit_date); 
      const uniqueVisitors = response.data.map(visit => visit.unique_visitors); 
 
      setChartData({ 
        labels: visitDates, 
        datasets: [ 
          { 
            label: 'Unique Visitors', 
            data: uniqueVisitors, 
            backgroundColor: 'rgba(75,192,192,0.2)', 
            borderColor: 'rgba(75,192,192,1)', 
            borderWidth: 1, 
            fill: true, 
          }, 
        ], 
      }); 
    } catch (error) { 
      console.error('Error fetching visit data:', error); 
      // Implement error handling for UI (e.g., display error message) 
      setIsLoading(false); // Set loading to false on error as well 
    } 
  }; 
 
  return ( 
    <div className="w-full max-w-2xl mx-auto p-4"> 
      <h2 className="text-2xl font-bold mb-4 text-center">Daily Unique Visits</h2> 
      {isLoading ? ( 
        <p>Loading data...</p> 
      ) : ( 
        <Line data={chartData} /> 
      )} 
    </div> 
  ); 
} 
 
export default DailyVisitsChart;