import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  const [cookies, setCookie] = useCookies(['darkMode']);

  useEffect(() => {
    // Fetch blog posts from the API
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://api.blackboxbonuses.com/api/blog');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  // Set initial state for dark mode using the cookie
  useEffect(() => {
    const darkModeCookie = cookies.darkMode;
    // Render dark mode if cookie is true or does not exist
    if (darkModeCookie === undefined || darkModeCookie === true) {
      setDarkMode(true);
      if (darkModeCookie === undefined) {
        setCookie('darkMode', 'true', { path: '/' }); // Initialize cookie if absent
      }
    } else {
      setDarkMode(false);
    }
  }, [cookies, setCookie]);

  // Toggle dark mode
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    setCookie('darkMode', newDarkMode.toString(), { path: '/' });
  };

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} min-h-screen transition-colors duration-300`}>
      {/* Navigation bar */}
      <nav className="flex justify-between items-center p-5 shadow-md">
        <a href="/" className="text-2xl font-semibold font-sans">BlackBoxBonuses</a>
        <div className="flex space-x-4">
          <button className="px-4 py-2 border rounded-lg focus:outline-none" onClick={toggleDarkMode}>
            {darkMode ? 'Light Mode' : 'Dark Mode'}
          </button>
        </div>
      </nav>

      {/* Blog posts */}
      <main className="flex flex-col items-center py-8 px-4">
        <h2 className="text-2xl font-bold mb-4">Top Picks</h2>
        <div className="flex justify-center items-center mt-2">
          <img src="/logo-removebg.png" alt="Logo" className="h-64 w-64 mb-5" />
        </div>
        {posts.map(post => (
          <div
            key={post.id}
            className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} max-w-4xl w-full shadow-lg rounded-lg mb-6 p-6 border dark:border-gray-700 transition-colors duration-300`}
          >
            <h2 className="text-2xl font-bold mb-2">{post.title}</h2>
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'} mb-4`}>
              By {post.author} on {new Date(post.date).toLocaleDateString()}
            </p>
            <p className={`mb-4 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {post.content}
            </p>
            <a
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Visit Site
            </a>
          </div>
        ))}
      </main>
    </div>
  );
}