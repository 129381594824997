// Import necessary libraries
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage';
import Sitelist from './Sitelist';
import Admin from './Admin';
import SearchResults from './SearchResults';
import Blog from './Blog';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

function App() {
  if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/sitelist" element={<Sitelist />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;