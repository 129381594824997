import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

export default function Sitelist() {
  const [sites, setSites] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(['clickedLinks', 'darkMode']);

  // Set initial state for dark mode using the cookie
  useEffect(() => {
    const darkModeCookie = cookies.darkMode;
    // Render dark mode if cookie is true or does not exist
    if (darkModeCookie === undefined || darkModeCookie === true) {
      setDarkMode(true);
      if (darkModeCookie === undefined) {
        setCookie('darkMode', 'true', { path: '/' }); // Initialize cookie if absent
      }
    } else {
      setDarkMode(false);
    }
  }, [cookies, setCookie]);

  // Function to fetch sites data
  const fetchSites = async () => {
    try {
      const response = await fetch('https://api.blackboxbonuses.com/api/bonuses?page=1&limit=10');
      const result = await response.json();
      setSites(result.data);
    } catch (error) {
      console.error('Error fetching the sites:', error);
    } finally {
      setLoading(false);
    }
  };

  const logVisit = async () => {
    try {
      await fetch('https://api.blackboxbonuses.com/api/log-visit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ visitTime: new Date().toISOString() })
      });
    } catch (error) {
      console.error('Error logging visit:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchSites();
    logVisit();
  }, []);

  // Toggle dark mode
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    setCookie('darkMode', newDarkMode.toString(), { path: '/' });
  };

  // Handle link click
  const handleLinkClick = (link) => {
    const updatedLinks = { ...cookies.clickedLinks, [link]: true };
    setCookie('clickedLinks', updatedLinks, { path: '/' });
  };

  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} min-h-screen transition-colors duration-300`}>
      <nav className="flex justify-between items-center p-5 shadow-md">
        <div className="text-2xl font-semibold font-sans"><a href="/">Black Box Bonuses</a></div>
        <button
          className="px-4 py-2 border rounded-lg focus:outline-none"
          onClick={toggleDarkMode}
        >
          {darkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </nav>

      <div className="p-5">
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <div className="overflow-x-auto">
            <table className={`min-w-full border-collapse ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg`}>
              <thead>
                <tr>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Status
                  </th>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Site Name
                  </th>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Bonus Description
                  </th>
                  <th className={`p-3 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'} font-bold text-left`}>
                    Site Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {sites.map((site, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? (darkMode ? 'bg-gray-700' : 'bg-gray-100') : (darkMode ? 'bg-gray-600' : 'bg-white')} transition-colors duration-200`}>
                    <td className="p-3 text-center">
                      <span className={`inline-block w-5 h-5 rounded-full ${cookies.clickedLinks && cookies.clickedLinks[site.bonus_link] ? 'bg-green-500' : 'bg-red-500'}`}></span>
                    </td>
                    <td className="p-3">
                      <a href={site.bonus_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline" onClick={() => handleLinkClick(site.bonus_link)}>
                        {site.casino_name}
                      </a>
                    </td>
                    <td className="p-3">{site.bonus_description}</td>
                    <td className="p-3">{site.site_description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* New Section */}
        <div className="pt-10">
          <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'} rounded-lg shadow-md`}>
            <h2 className="text-3xl font-semibold mb-4">What We Do</h2>
            <p className="mb-4">
              At Black Box Bonuses, we specialize in finding the <strong>best sign up bonuses</strong> available. Whether
              it’s <strong>free slot spins</strong>, <strong>no deposit bonuses</strong>, or exclusive <strong>sign up bonuses</strong>, we’ve got you covered. Our
              team continuously scours the internet to bring you the latest and greatest in bonus offers.
            </p>
            <p className="mb-4">
              Looking for a <strong>free bonus</strong>? Or maybe you're interested in trying out exclusive <strong>free slot spins</strong> on
              the hottest games? Look no further! We make it our mission to provide you with up-to-date information on
              the most rewarding bonuses around.
            </p>
            <p>
              Our platform ensures that all the details regarding <strong>sign up bonuses</strong> are clear and
              straightforward, so you can enjoy a hassle-free gaming experience. Dive into our listings and discover
              your next big win today!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}