// Import necessary libraries and hooks
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import Admin from './Admin';

// Create and export the ContactUs component
export default function ContactUs() {
  const [darkMode, setDarkMode] = useState(true);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [cookies, setCookie] = useCookies(['darkMode']);

  // Handle form data change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.blackboxbonuses.com/api/contact', formData);
      setSubmissionStatus(response.data.message);
      setFormData({ name: '', email: '', message: '' }); // Reset form after submission
    } catch (error) {
      console.error('There was an error submitting the contact form:', error.message);
      setSubmissionStatus('There was an error submitting the form.');
    }
  };

  // Set initial state for dark mode using the cookie
  useEffect(() => {
    const darkModeCookie = cookies.darkMode;
    // Render dark mode if cookie is true or does not exist
    if (darkModeCookie === undefined || darkModeCookie === true) {
      setDarkMode(true);
      if (darkModeCookie === undefined) {
        setCookie('darkMode', 'true', { path: '/' }); // Initialize cookie if absent
      }
    } else {
      setDarkMode(false);
    }
  }, [cookies, setCookie]);

  // Toggle dark mode
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    setCookie('darkMode', newDarkMode.toString(), { path: '/' });
  };

  function loadAdminPanel() {
    setShowAdminPanel(true);
  }


  return (
    <div className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'} min-h-screen transition-colors duration-300 flex flex-col`}>
      <Helmet>
        <title>Black Box Bonuses - Contact Us</title>
        <meta name="description" content="A simple way to contact us plus all of our social media profiles " />
        <meta name="keywords" content="blackboxbonuses, blackboxbonuses support, blackboxbonuses contact" />
      </Helmet>
      {/* Navigation bar */}
      <nav className="flex justify-between items-center p-5 shadow-md">
        <a href='/' className="text-2xl font-semibold font-sans">Black Box Bonuses</a>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          onClick={toggleDarkMode}
        >
          {darkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </nav>

      {/* Main content */}
      <main className="flex-grow p-8 space-y-12">
        <div className="flex justify-center items-center mt-8">
          <img src="/logo-removebg.png" alt="Logo" className="h-64 w-64" />
        </div>
        <section className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl font-bold font-sans mb-4">Contact Us</h1>
          <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            We'd love to hear from you. Whether you have a question about our services, need assistance, have partnership questions, or just want to say hi, we're here for you.
          </p>
        </section>

        <section className="max-w-4xl mx-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              />
            </div>
            <div>
              <label htmlFor="email" className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              />
            </div>
            <div>
              <label htmlFor="message" className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>Message</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              ></textarea>
            </div>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 w-full">
              Send Message
            </button>
          </form>
          {submissionStatus && (
            <p className="mt-4 text-sm text-green-500">{submissionStatus}</p>
          )}
        </section>
        {showAdminPanel && <Admin />}
      </main>

      {/* Footer */}
      <footer className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} p-5 text-center`}>
  <p className={`${darkMode ? 'text-gray-400' : 'text-gray-700'} text-sm`}>
    © 2024 BlackBoxBonuses. All rights reserved
    {/*Make it so that the user loads the admin component when clicked*/}
    <a onClick={loadAdminPanel} className="text-inherit no-underline">.</a>
  </p>
</footer>
    </div>
  );
}